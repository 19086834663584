* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.backGround {
  background-image: url('assets/images/png/bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;
}

@media only screen and (min-width: 1020px) {
  /* For desktop: */
  .responsive {max-width: 760px; text-align: left; margin: 0px auto}
}
#search::placeholder {
  font-family: "DM Sans";
  font-size: 12px;
  line-height: 14.6px;
  letter-spacing: -2%;
}

.checked-icon:checked::after {
  content: '';
  background: url('./assets/images/svg/check.svg') no-repeat center center;
  width: 12px; 
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-bg {
  background-image: url('./assets/images/svg/bg-home.svg');
  background-repeat: no-repeat;
}




@font-face {
  font-family: 'Denton Test';
  src: url('./assets/fonts/Denton Test Medium 500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

.linearGradient-to-r {
  background: linear-gradient(to right, #FBFAF3 32px, rgba(251, 250, 243, 1) 32px, rgba(251, 250, 243, 0) 100%);

}

.linearGradient-to-l {
  background: linear-gradient(to left, #FBFAF3 32px, rgba(251, 250, 243, 1) 32px, rgba(251, 250, 243, 0) 100%);
}

#search-container div {
  width: 100% !important;
  height: 100% !important;
}